"use client";
import * as React from "react";
import * as z from "zod";
import { signIn, useSession } from "next-auth/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRouter } from "next/navigation";
import { SubmitHandler, useForm } from "react-hook-form";

import { Button } from "@/components/ui/button";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { MixpanelTracking } from "@/lib/mixpanel-browser-analytics";
import { debugLog } from "@/services/logging";

const formSchema = z.object({
	email: z.string().email(),
});

type EmailFormValues = z.infer<typeof formSchema>;

export const EmailLogin: React.FC = () => {
	const { data: session } = useSession();
	const router = useRouter();

	const form = useForm<EmailFormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: "",
		},
	});

	const onSubmit: SubmitHandler<EmailFormValues> = async (values) => {
		debugLog(values);
		const result = await signIn("email", {
			email: values.email,
			callbackUrl: "/",
		});
		//todo: [check if tracking gets hit after sign in]
		MixpanelTracking.getInstance().signIn({sign_in_type:'email', user_email: values.email});
		debugLog(result);
	};

	if (session) {
		router.push("/");
	}


	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
				<FormField
					control={form.control}
					name="email"
					render={({ field }) => (
						<FormItem>
							<FormControl>
								<Input
									type="email"
									className="w-full px-4 py-2 rounded border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
									placeholder="Enter your email"
									{...field}
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<Button
					type="submit"
					className="w-full font-bold bg-burgundy hover:bg-pink-600 text-white rounded-lg shadow px-4 py-4 mt-2">
					Login or Register with email
				</Button>
			</form>
		</Form>
	);
};
