"use client";
import { OAuthLogin } from "@/components/oauth-login";
import { EmailLogin } from "@/components/email-login";
import TrackPageView from "@/lib/page-view-tracker";
import { useSearchParams } from "next/navigation";
import { useToast } from "@/components/ui/use-toast";
import { useEffect, useState } from "react";
import {
	Toast,
	ToastClose,
	ToastDescription,
	ToastProvider,
	ToastTitle,
	ToastViewport,
} from "@/components/ui/toast";

export default function Login() {
	const error = useSearchParams().get("error");
	const [showError, setShowError] = useState(false);

	useEffect(() => {
		if (error === "OAuthAccountNotLinked") {
			setShowError(true);
		}
	}, [error]);

	return (
		<div className="flex justify-center w-full bg-slate-700">
			<div className="flex flex-col pt-10 max-w-[1480px] justify-center w-full bg-slate-700 md:py-28 md:flex-row">
				<TrackPageView />
				{showError && (
					<ToastProvider>
						<Toast variant={"info"}>
							<div className="grid gap-1">
								<ToastTitle>Couldn&apos;t Sign In</ToastTitle>

								<ToastDescription>
									We are unable link your account at this time. Please
									try to sign in using your email
								</ToastDescription>
							</div>
							<ToastClose />
						</Toast>

						<ToastViewport />
					</ToastProvider>
				)}

				<div className="w-full md:w-1/2 flex flex-col text-white">
					<div className="flex justify-center mb-5 px-3 md:justify-start lg:px-12">
						<iframe
							className="w-full h-80 rounded-md max-w-[568px]"
							src={
								"https://www.youtube.com/embed/oCQg-52CPgg?autoplay=1&mute=1"
							}
							title={"Steps"}
							frameBorder="0"
							allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen></iframe>
					</div>

					<h1 className="text-4xl md:text-6xl font-bold px-12 md:px-3 lg:px-12">
						Work{" "}
						<span className="text-gold">
							with people
							<br />
						</span>{" "}
						<span className="text-burgundy">for</span> people.
					</h1>
				</div>
				<div className="w-full bg-white p-8 flex flex-col rounded-t-lg rounded-b-none max-h-[520px] md:rounded-lg md:w-1/3 ">
					<h2 className="text-2xl font-bold mb-6 flex items-center justify-center">
						Login to Bazaar Room
					</h2>

					<div className="flex items-center justify-center">
						<OAuthLogin
							provider="google"
							imagePath="/images/google.png"
							imageAlt="Google Logo"
							text="Login or Register with Google"
						/>
					</div>
					<div className="flex items-center justify-center">
						<OAuthLogin
							provider="apple"
							imagePath="/images/apple.svg"
							imageAlt="Apple Logo"
							text="Login or Register with Apple"
						/>
					</div>
					<div className="flex items-center mb-6">
						<div className="w-1/2 h-0.5 bg-gray-400"></div>
						<div className="w-12 flex justify-center items-center">
							<div className="bg-white border border-slate-600 w-8 h-8 rounded-full flex justify-center items-center">
								<span className="text-slate-600 font-bold">or</span>
							</div>
						</div>
						<div className="w-1/2 h-0.5 bg-gray-400"></div>
					</div>
					<div className="flex items-start justify-start">
						<EmailLogin />
					</div>
				</div>
			</div>
		</div>
	);
}
