"use client";
import Image from "next/image";
import { LiteralUnion, signIn, useSession } from "next-auth/react";
import { type BuiltInProviderType } from "next-auth/providers";
import { MixpanelTracking } from "@/lib/mixpanel-browser-analytics";
import { useSearchParams } from "next/navigation";
import { useToast } from "./ui/use-toast";
import { useEffect } from "react";

interface OAuthLoginProps {
	provider: LiteralUnion<BuiltInProviderType>;
	imagePath: string;
	imageAlt: string;
	imageWidth?: number;
	imageheight?: number;
	text: string;
}

export const OAuthLogin: React.FC<OAuthLoginProps> = ({
	imageAlt,
	imagePath,
	provider,
	text,
	imageWidth,
	imageheight,
}) => {
	const handleSignIn = async () => {
		await signIn(provider, {
			callbackUrl: "/",
		});
		//todo: add userid/email to tracking info
		MixpanelTracking.getInstance().signIn({
			sign_in_type: "oauth",
			oauth_provider: provider,
		});
	};

	return (
		<button
			className="flex items-center justify-center bg-white text-gray-800 rounded-lg shadow hover:scale-95 transform transition px-4 py-2 mb-6 mx-2"
			onClick={handleSignIn}>
			<Image
				src={imagePath}
				alt={imageAlt}
				width={imageWidth ?? 20}
				height={imageheight ?? 20}
				className="m-1"
			/>
			{text}
		</button>
	);
};
